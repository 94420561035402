import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function CustomerInformations(props) {
    const { TransactionBuyers } = props;

    // Helper function to join name parts with optional title
    const formatName = (firstName, lastName) => {
        return `${firstName} ${lastName}`.trim();
    };

    const isBillingAddressDifferent = () => {
        return !TransactionBuyers ||
            TransactionBuyers.billingStreet !== TransactionBuyers.deliveryStreet ||
            TransactionBuyers.billingCity !== TransactionBuyers.deliveryCity ||
            TransactionBuyers.billingPostal_code !== TransactionBuyers.deliveryPostal_code ||
            TransactionBuyers.billingHousenumber !== TransactionBuyers.deliveryHousenumber;
    };

    return (
        <View>
            <View>
                <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                    Kundendaten
                </Text>
            </View>
            {TransactionBuyers && (
                <View style={orderTrackingStyles.text}>
                    {TransactionBuyers.deliveryCompany && (
                    <Text style={orderTrackingStyles.text}>{TransactionBuyers.deliveryCompany}</Text>
                )}
                <Text style={orderTrackingStyles.text}>
                    {formatName(
                        TransactionBuyers.deliveryFirstName,
                        TransactionBuyers.deliveryLastName
                    )}
                </Text>
                {TransactionBuyers.deliveryAddressDetails && (
                    <Text style={orderTrackingStyles.text}>{TransactionBuyers.deliveryAddressDetails}</Text>
                )}
                <Text style={orderTrackingStyles.text}>
                    {TransactionBuyers.deliveryStreet} {TransactionBuyers.deliveryHousenumber}
                </Text>
                <Text style={orderTrackingStyles.text}>
                    {TransactionBuyers.deliveryPostal_code} {TransactionBuyers.deliveryCity}
                </Text>
                {TransactionBuyers.deliveryCountry && (
                    <Text style={orderTrackingStyles.text}>{TransactionBuyers.deliveryCountry}</Text>
                )}
                <Text style={orderTrackingStyles.text}>{TransactionBuyers.email}</Text>
                {TransactionBuyers.phoneNumbers && (
                    <Text style={orderTrackingStyles.text}>{TransactionBuyers.phoneNumbers}</Text>
                )}
                    {TransactionBuyers.comment && <Text style={orderTrackingStyles.text}>{TransactionBuyers.comment}</Text>}
                    </View>
            )}
            {/* Billing address */}
            {isBillingAddressDifferent() && (
                <View style={orderTrackingStyles.billingAddress}>
                    <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                        Abweichende Rechnungsadresse
                    </Text>
                    {TransactionBuyers.billingCompany && (
                        <Text style={orderTrackingStyles.text}>{TransactionBuyers.billingCompany}</Text>
                    )}
                    <Text style={orderTrackingStyles.text}>
                        {TransactionBuyers.name}
                    </Text>
                    {TransactionBuyers.billingAddressDetails && (
                        <Text style={orderTrackingStyles.text}>{TransactionBuyers.billingAddressDetails}</Text>
                    )}
                    <Text style={orderTrackingStyles.text}>
                        {TransactionBuyers.billingStreet} {TransactionBuyers.billingHousenumber}
                    </Text>
                    <Text style={orderTrackingStyles.text}>
                        {TransactionBuyers.billingPostal_code} {TransactionBuyers.billingCity}
                    </Text>
                    {TransactionBuyers.billingCountry && (
                        <Text style={orderTrackingStyles.text}>{TransactionBuyers.billingCountry}</Text>
                    )}
                </View>
            )}
        </View>
    );
}
